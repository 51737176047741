<template>
  <page-content>

    <common-table
      ref="table"
      path="dept"
      :tree="true"
      :pageable="false"
      :columns="columns">

      <template slot="search">
        <a-button type="primary" ghost @click="add" v-hasPermission="['dept:add']">新增</a-button>
      </template>


      <template slot="operation" slot-scope="{record}">
        <action-edit v-hasPermission="['dept:update']" @click="edit(record)"></action-edit>
        <action-delete v-hasPermission="['dept:delete']" @click="remove(record)"></action-delete>
        <a-badge v-hasNoPermission="['dept:update','dept:delete']" status="warning" text="无权限"></a-badge>
      </template>

    </common-table>

    <!--新增，修改部门 -->
    <dept-edit ref="deptEdit" @success="getData" ></dept-edit>

  </page-content>
</template>

<script>
  import DeptEdit from './DeptEdit'

  export default {
    name: 'Dept',
    components: {
       DeptEdit
    },
    data() {
      return {

      }
    },
    computed: {
      columns() {
        return [
          {
            title: '名称',
            dataIndex: 'text'
          }, {
            title: '排序',
            dataIndex: 'order'
          }, {
            title: '创建时间',
            dataIndex: 'createTime',
            sorter: true,
          }, {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: {customRender: 'operation'},
            width: 120
          }]
      }
    },

    methods: {

      add() {
        this.$refs.deptEdit.show()
      },
      edit(record) {
        this.$refs.deptEdit.show(record)
      },
      remove(record) {
        if(record.children && record.children.length>0){
          this.$message.error('请先删除该部门的下级部门')
          return
        }
        let that = this
        this.$confirm({
          title: '确定删除部门 ' + record.text,
          content: '该操作无法撤销',
          centered: true,
          onOk() {
            that.$delete('dept/' + record.id).then(() => {
              that.$message.success('删除成功')
              that.getData()
            })
          }
        })
      },

      getData(){
        this.$refs.table.getData()
      }
    }
  }
</script>

<style lang="less" scoped>

</style>
